import apiRequest from '../apis/api-request';
import { userStore } from '../store/userStore';

//const accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwibmFtZSI6IlRFU1QiLCJzY2hlbWEiOiJjb21wYW55XzEiLCJpYXQiOjE2OTM2NDY0OTR9.i5lFYDbnRbiZpe6JTtLNS7sFdhNpGY5Sl2_VCodzYpo'
const accessToken = userStore.getState().user.jwttoken
const schema= userStore.getState().companyData?.company;
const authService = {

    login: async (user) => {
        return apiRequest({
            method:"POST",
            url: "/api/user/login",
            data: user,
        });
    },

    shipTypeList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/ship_types?search="+data.search+'&status=&page='+data.page+'&size='+data.size,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    shipTypeAdd: async (data) => {
        console.log("shipTypeAdd",data)
        return apiRequest({
            method:"POST",
            url: "/api/ship_types",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    shipTypeUpdate: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/ship_types/" +  data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    deleteShipType: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/ship_types/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    rankList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/rankonboard?search="+data.search+'&status=&page='+data.page+'&size='+data.size,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    rankAdd: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/rankonboard",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    rankUpdate: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/rankonboard/" +  data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    deleteRank: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/rankonboard/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

//category
    categoryList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/category?search="+data.search+'&status=&page='+data.page+'&size='+data.size,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    categoryAdd: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/category",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    categoryUpdate: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/category/" +  data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    categoryDelete: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/category/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    //country
    countryList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/country?search="+data.search+'&status=&page='+data.page+'&size='+data.size,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    countryAdd: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/country",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    countryUpdate: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/country/" +  data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    countryDelete: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/country/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    //company
    companyList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/company?search="+data.search+'&status=&page='+data.page+'&size='+data.size,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    companyAdd: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/company",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    companyUpdate: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/company/" +  data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    companyDelete: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/company/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },    
    
    companyView: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/api/company/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    imageUploadSingle: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/fileupload/single/upload",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type":  "multipart/form-data"
            }
        });
    },

    //office
    officeList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/office?search="+data.search+'&status=&page='+data.page+'&size='+data.size,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    officeAdd: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/office",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    officeUpdate: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/office/" +  data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    officeDelete: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/office/" + id,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

//doc Holder
      docList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/doc_holder?search="+data.search+'&status='+data.status+'&page='+data.page+'&size='+data.size,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema,
                
            }
        });
    },

    docAdd: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/doc_holder",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    docUpdate: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/doc_holder/" +  data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    docDelete: async (data) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/doc_holder/" + data.id,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    docVesselList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/doc_holder/vessel/doclist?search="+data.search+'&status=&page='+data.page+'&size='+data.size,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema,
                
            }
        });
    },

//User
    userList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/user?search="+data.search+'&status='+data.status+'&page='+data.page+'&size='+data.size+'&role='+data.role,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    userAdd: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/user",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    userUpdate: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/user/" +  data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    userDelete: async (data) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/user/" + data.id,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    accountuserList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/user?search="+data.search+'&status=&page='+data.page+'&size='+data.size,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    userdocList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/user/doc?id="+data.id,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

 //roles   
    roleList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/roles",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    userroleList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/user/roles",
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

//fleet
    fleetList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/fleets?search="+data.search+'&status='+data.status+'&page='+data.page+'&size='+data.size+'&doc='+data.doc,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    fleetAdd: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/fleets",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    fleetUpdate: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/fleets/" +  data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    fleetDelete: async (data) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/fleets/" + data.id,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    //vessel
    vesselList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/vessels?search="+data.search+'&status='+data.status+'&page='+data.page+'&size='+data.size+'&doc='+data.doc+'&fleet='+data.fleet,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    vesselAdd: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/vessels",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    vesselUpdate: async (data) => {
        return apiRequest({
            method:"PUT",
            url: "/api/vessels/" +  data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    vesselDelete: async (data) => {
        return apiRequest({
            method:"DELETE",
            url: "/api/vessels/" + data.id,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    
    vesselView: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/vessels/" +  data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    //compliant
    compliantlList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/compliant?search="+data.search+'&status=&page='+data.page+'&size='+data.size+'&doc='+data.doc+'&vessel='+data.vessel+'&accountStatus='+data.accountStatus+'&category='+data.category+'&platform='+data.platform+'&reference='+data.reference,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },
    
    complaintListIssues: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/compliant/issues?search=${data.search}&status=&page=${data.page}&size=${data.size}&doc=${data.doc}&vessel=${data.vessel}&accountStatus=${data.accountStatus}&category=${data.category}&platform=${data.platform}&reference=${data.reference}&daysort=${data.daysort}`,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },
    

    compliantlView: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/compliant/"+data.id,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    assignCategory: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/action",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    contactInformant: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/compliant/mail/contactinformant",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    sendResponse: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/compliant/mail/sendresponse",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    scheduleLater: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/compliant/mail/scheduleresponse",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    lessonlearned: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/compliant/mail/lesson",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    compliantCount: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/compliant/issue/count",
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    compliantAssignedCount: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/compliant/issue/assigned/count",
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    companyDataCount: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/compliant/company/count",
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },


    accountuserDocList: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/user/${data.id}`,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },  
    
    accountuserFullDocList: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/user/doc/account/user`,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },  

    accountuserForVesselDocWiseList: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/user/accountuser/list/${data.id}`,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },  
    
    complaintReassign: async (data) => {
        return apiRequest({
            method:"PATCH",
            url: `/api/compliant/reassign`,
            data: data.data,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    complaintAccept: async (data) => {
        return apiRequest({
            method:"PATCH",
            url: `/api/compliant/accept`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    //Account User
    accntuserList: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/user/accountuser/list?search=${data.search}&status=&page=${data.page}&size=${data.size}&au=${data.au}`,
            data: {user: [1, 3]},
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

// User Exist
        userExist: async (data) => {
            return apiRequest({
                method:"GET",
                url: "/api/user/username/exist/"+data.username+"?userid="+data.userid,
                headers: {
                    Authorization: "Bearer " + accessToken,
                    
                }
            });
        },

// vessel Exist
        vesselExist: async (data) => {
            return apiRequest({
                method:"GET",
                url: "/api/vessels/vessel/exist?name="+data.vessel+"&id="+data.id,
                headers: {
                    Authorization: "Bearer " + accessToken,
                    'schema': data.schema
                }
            });
        },

// vessel Exist
        vesselImoExist: async (data) => {
            return apiRequest({
                method:"GET",
                url: "/api/vessels/vessel/imo/exist?imo="+data.imo+"&id="+data.id,
                headers: {
                    Authorization: "Bearer " + accessToken,
                    'schema': data.schema
                }
            });
        },

        compliantPublicList: async (data) => {

            const size = data?.size ? data?.size : 10
            const page = data?.page ? data?.page : 0

            return apiRequest({
                method:"GET",
                url: `/api/public?page=${page}&size=${size}&accountStatus=${data.accountStatus}&category=${data.category}&platform=${data.platform}&reference=${data.reference}`,
                headers: {
                    Authorization: "Bearer " + accessToken,
                }
            });
        },
        
        compliantPublicView: async (id) => {
            return apiRequest({
                method:"GET",
                url: `/api/public/${id}`,
                headers: {
                    Authorization: "Bearer " + accessToken,
                }
            });
        },
// user view
        userView: async (data) => {
            return apiRequest({
                method:"GET",
                url: "api/user/find/user/details/"+data.id,

                headers: {
                    Authorization: "Bearer " + accessToken,
                }
            });
        },


//super user list       
    complaintListSuperuserIssues: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/api/compliant/superuser/issues?search=${data.search}&status=&page=${data.page}&size=${data.size}&doc=${data.doc}&vessel=${data.vessel}&accountStatus=${data.accountStatus}&category=${data.category}&platform=${data.platform}&reference=${data.reference}&daysort=${data.daysort}`,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    sendCancellation: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/compliant/mail/contactinformant/cancel/"+data.complaintId,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

//reports
    vesselReportList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/vessel?search="+data.search+'&status=&page='+data.page+'&size='+data.size+'&start='+data.start+'&end='+data.end,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    vesselStatusReportList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/vesselstatus?start="+data.start+'&end='+data.end,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    vesselReportExport: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/vessel/export",
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    requestDemo: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/public/requestdemo",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    contactUs: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/public/contact",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    forgotPass: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/public/forgotpass",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    resetPass: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/public/reset/"+data.id,
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },
    
    categoryChart: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/categoychart",
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    vesselChart: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/vesselchart",
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    CategoryReport: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/category?fromDate="+data.fromDate+'&toDate='+data.toDate,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
                
            }
          
        });
    },
    
    VesselcompanyReport: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/vesselreport?fromDate="+data.fromDate+'&toDate='+data.toDate,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
                
            }
          
        });
    },

    platformChart: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/platformchart",
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    shipTypeChart: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/shiptype",
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    rankChart: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/rankonboard",
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    help: async (data) => {
        return apiRequest({
            method:"POST",
            url: "/api/public/help",
            data:data,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    demoList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/public/demo/request?search="+data.search+'&page='+data.page+'&size='+data.size,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    companyCountList: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/company/list/company?search="+data.search+'&page='+data.page+'&size='+data.size,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    surveyReport: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/public/survey/report?fromdate="+data.fromdate+'&todate='+data.todate+'&rank='+data.rank+'&ship='+data.ship+'&age='+data.age+'&gender='+data.gender,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },

    compliantlExport: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/issues?search="+data.search+'&status=&doc='+data.doc+'&vessel='+data.vessel+'&accountStatus='+data.accountStatus+'&category='+data.category+'&platform='+data.platform,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },
      
    
    companyExport: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/complaint",
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    categoryExport: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/category",
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    categoryNocode: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/categorynocode?fromDate="+data.fromDate+"&toDate="+data.toDate,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    rankonboardNocode: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/rankonboardnocode?fromDate="+data.fromDate+"&toDate="+data.toDate,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    TotalReport: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/workplace?fromDate="+data.fromdate+"&toDate="+data.todate,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
                
            }
          
        });
    },

    ProifileAPI: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/public/app/profile?search="+data.search+'&page='+data.page+'&size='+data.size,
        });
    },

    shipadmincompliantlExport: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/issues/admin?search="+data.search+'&status=&doc='+data.doc+'&vessel='+data.vessel+'&accountStatus='+data.accountStatus+'&category='+data.category+'&platform='+data.platform,
            headers: {
                Authorization: "Bearer " + accessToken,
                'schema': data.schema
            }
        });
    },

    shipadmincompliantNocodelExport: async (data) => {
        return apiRequest({
            method:"GET",
            url: "/api/report/issues/admin/nocode?search="+data.search+'&category='+data.category+'&platform='+data.platform,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },
}


export default authService;